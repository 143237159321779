import { Link } from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import { useEffect, useState } from "react";
import AreaList from './arealist';
import {locations} from './constant';
import district from '../images/searchimage.jpg';
import RecentComment from './recentcomment';
import writingman from '../images/writingman.png'

const server_address = process.env.REACT_APP_DOMAIN || ""

function GridCard(){
  const [reviewCounts, setReviewCounts] = useState({});
  const [lastUpdate, setLastUpdate] = useState('');
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    async function fetchReviewCounts() {
      try {
          const countsPromises = locations.map(async (location) => {
            const response = await fetch(
              `${server_address}record/count?field=${location.name.toString()}`,
            );

          if (!response.ok) {
            return { name: location.name, count: 0 };
          }

          const data = await response.json();
          return { name: location.name, count: data.results , lastUpdate: data.lastUpdate};
        });

        const counts = await Promise.all(countsPromises);

        const reviewCountsMap = counts.reduce((map, count) => {
          map[count.name] = count.count;
          return map;
        }, {});

        const lastUpdateMap = counts.reduce((map2, lastUpdate) => {
          map2[lastUpdate.name] = lastUpdate.lastUpdate;
          return map2;
        }, {});

        setReviewCounts(reviewCountsMap);
        setLastUpdate(lastUpdateMap);
      } catch (error) {
        console.error('Error retrieving review counts:', error);
      }
    }

    fetchReviewCounts();
  }, []);

  return (
    <div class="container mt-4 px-2">
    <h4>瀏覽最多人討論的地區{'\u{1F525}'}</h4>
    <div className="row row-cols-1 row-cols-md-5 g-4 py-4">
      {locations.map((location, index) => (
        <div key={index} className="col">
            <div className="card h-100">
              <Link to={{pathname: `/location/${location.name.replace(/\s/g, '').toLowerCase()}`,
              state: {name: location.name}
            }}>
              <img src={district} className="card-img-top img-fluid" alt={location.name} />
              </Link>
              <div className="card-body">
                <Link to={`/location/${location.name.replace(/\s/g, '').toLowerCase()}`} className="link-style">
                <h5 className="card-title">{location.chinese} </h5>
                </Link>
                <p className="card-text">{reviewCounts[location.name] ?? 0} 評論</p>
              </div>
              <div className="card-footer">
                <small className="text-body-secondary">最後更新於{lastUpdate[location.name] ? lastUpdate[location.name].split('T')[0] : "2024-04-01"}</small>
              </div>
            </div>
        </div>
      ))}
    </div>
    <RecentComment/>
    <div class="bg-light rounded">
      <div class="jumbotron writing">
        <div>
        <h2>分享你的意見</h2>
        <p className="lead">你也可以幫我們寫評論, 與其他用戶分享你的想法</p>
        <a class="btn btn-primary" onClick={loginWithRedirect}>撰寫評論 &raquo;</a>
        </div>
        {/*<img className="writingman" src={writingman}/>*/}
      </div>
    </div>
    <AreaList/>
    </div>
  );
}

export default GridCard;