import SearchResult from "./searchresult"

function SearchResultContainer({results}){

    return (
        <div className="container result-list">
            {
                results.map((result) => {
                    return <SearchResult result={result} key = {result._id}></SearchResult>
                })
            }
        </div>
    )
}

export default SearchResultContainer