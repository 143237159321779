import { Link } from 'react-router-dom';

const AreaList = () => {
    const HK = [
        {area: "堅尼地城", areaEN: "kennedytown"},
        {area: "西營盤", areaEN: "saiyingpun"},
        {area: "石塘咀", areaEN: "shektongtsui"},
        {area: "中上環", areaEN: "central"},
        {area: "中西半山", areaEN: "midlevels"},
        {area: "灣仔", areaEN: "wanchai"},
        {area: "金鐘", areaEN: "admiralty"},
        {area: "銅鑼灣", areaEN: "causewaybay"},
        {area: "跑馬地", areaEN: "happyvalley"},
        {area: "天后", areaEN: "tinhau"},
        {area: "大坑", areaEN: "taihang"},
        {area: "北角", areaEN: "northpoint"},
        {area: "炮台山", areaEN: "fortresshill"},
        {area: "北角半山", areaEN: "northpointhill"},
        {area: "鰂魚涌", areaEN: "quarrybay"},
        {area: "太古城", areaEN: "taikoo"},
        {area: "西灣河", areaEN: "saiwanho"},
        {area: "筲箕灣", areaEN: "shaukeiwan"},
        {area: "杏花邨", areaEN: "kornhill"},
        {area: "柴灣", areaEN: "chaiwan"},
        {area: "小西灣", areaEN: "saiwanho"},
        {area: "石澳", areaEN: "sheko"},
        {area: "香港仔", areaEN: "aberdeen"},
        {area: "鴨脷洲", areaEN: "apleichau"},
        {area: "黃竹坑", areaEN: "wongchukhang"},
        {area: "南區", areaEN: "southern district"}
        ];

    const KL = [
        {area: "藍田", areaEN: "lamtin"},
        {area: "油塘", areaEN: "yautong"},
        {area: "觀塘", areaEN: "kwuntong"},
        {area: "牛頭角", areaEN: "ngautaukok"},
        {area: "九龍灣", areaEN: "kowloonbay"},
        {area: "牛池灣", areaEN: "ngautsuiwan"},
        {area: "鑽石山", areaEN: "diamondhill"},
        {area: "樂富", areaEN: "lokfu"},
        {area: "土瓜灣", areaEN: "tokekwan"},
        {area: "九龍城", areaEN: "kowlooncity"},
        {area: "啟德", areaEN: "kaitak"},
        {area: "新蒲崗", areaEN: "sanpokong"},
        {area: "黃大仙", areaEN: "wongtaisin"},
        {area: "九龍塘", areaEN: "kowloontong"},
        {area: "何文田", areaEN: "homantin"},
        {area: "又一村", areaEN: "yauyatchuen"},
        {area: "深水埗", areaEN: "shamshuipo"},
        {area: "石硤尾", areaEN: "shekkipmei"},
        {area: "南昌", areaEN: "namcheong"},
        {area: "荔枝角", areaEN: "laichikok"},
        {area: "長沙灣", areaEN: "cheungsawan"},
        {area: "美孚", areaEN: "meifoo"},
        {area: "荔景", areaEN: "laiking"},
        {area: "大角咀", areaEN: "dikoktsui"},
        {area: "奧運", areaEN: "olympic"},
        {area: "九龍站", areaEN: "kowloonstation"},
        {area: "太子", areaEN: "princeedward"},
        {area: "旺角", areaEN: "mongkok"},
        {area: "油麻地", areaEN: "yaumatei"},
        {area: "佐敦", areaEN: "jordan"},
        {area: "尖沙咀", areaEN: "tsimshatsui"},
        {area: "紅磡", areaEN: "hunghom"},
        {area: "黃埔", areaEN: "whampoa"}
    ]

    const NT = [
        {area: "西貢", areaEN: "saikung"},
        {area: "清水灣", areaEN: "clearwaterbay"},
        {area: "將軍澳", areaEN: "tseungkwano"},
        {area: "日出康城", areaEN: "lohaspark"},
        {area: "馬鞍山", areaEN: "maonshan"},
        {area: "沙田", areaEN: "shatin"},
        {area: "大圍", areaEN: "taiwai"},
        {area: "火炭", areaEN: "fotan"},
        {area: "大埔", areaEN: "taipo"},
        {area: "太和", areaEN: "taiwo"},
        {area: "粉嶺", areaEN: "fanling"},
        {area: "上水", areaEN: "sheungshui"},
        {area: "元朗", areaEN: "yuenlong"},
        {area: "洪水橋", areaEN: "hungshuikiu"},
        {area: "天水圍", areaEN: "tinshuiwai"},
        {area: "屯門", areaEN: "tuenmun"},
        {area: "深井", areaEN: "samtseng"},
        {area: "荃灣", areaEN: "tsuenwan"},
        {area: "大窩口", areaEN: "taiwohau"},
        {area: "葵涌", areaEN: "kwaichung"},
        {area: "葵芳", areaEN: "kwaifong"},
        {area: "青衣", areaEN: "tsingyi"},
        {area: "沙頭角", areaEN: "shataukok"}
    ];

  return (
    <div className='area-list py-4'>
    <h4>瀏覽其他地區</h4>
    <h5>香港島</h5>
    <div className="row row-cols-1 row-cols-md-5">
        {HK.map((item) => (
        <Link to={`/location/${item.areaEN.replace(/\s/g, '').toLowerCase()}`} className="link-style">
        <div className="item link-secondary">{item.area}</div>
        </Link>
        ))}
    </div>
    <br></br>
    <h5>九龍</h5>
    <div className="row row-cols-1 row-cols-md-5">
    {KL.map((item) => (
        <Link to={`/location/${item.areaEN.replace(/\s/g, '').toLowerCase()}`} className="link-style">
        <div className="item link-secondary">{item.area}</div>
        </Link>
        ))}    
    </div>
    <br></br>
    <h5>新界</h5>
    <div className="row row-cols-1 row-cols-md-5">
    {NT.map((item) => (
        <Link to={`/location/${item.areaEN.replace(/\s/g, '').toLowerCase()}`} className="link-style">
        <div className="item link-secondary">{item.area}</div>
        </Link>
        ))}    
    </div>
    </div>  
    );
};


export default AreaList;