function Footer() {
    return (
      <footer className="footer bg-light text-dark py-4 px-4 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5>Rate My House - 住宅評論網</h5>
              <p>查看你目標住宅的評論</p>
            </div>
            <div className="col-md-3">
              <h5>快速連結</h5>
              <ul className="list-unstyled">
                <li><a href="#">主頁</a></li>
                <li><a href="#">租客專區</a></li>
                <li><a href="#">關於</a></li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5>聯絡我們</h5>
              <ul className="list-unstyled">
                <li>support@ratemyhousehk.com</li>
              </ul>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-md-12 text-center">
              <p>&copy; 2024 Rate My House. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;