import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './components/navbar';
import SearchForm from './components/searchform';
import GridCard from './components/gridcard';
import Footer from './components/footer';
import Location from './components/location';
import Review from './components/review';
import SearchResultContainer from './components/searchcontainer';
import Resource from './components/resource'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useState } from 'react';

function App() {

  const [results, setResult] = useState([])

  return (
    <>
    <Router>
      <Navbar/>
      <SearchForm setResult={setResult}/>
      <SearchResultContainer results={results}/>
      <Routes>
        <Route path="/" element={<GridCard/>} /> 
        <Route path="/location/:name" element={<Location/>} />
        <Route path="/review/:name" element={<Review/>} />
        <Route path="/resource" element={<Resource/>} />
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
