import {useAuth0} from '@auth0/auth0-react';

function LoginButton(){
    const { loginWithRedirect, isAuthenticated} = useAuth0();

    return (
        !isAuthenticated && (
            <button class="btn btn-primary" type="button" onClick={() => loginWithRedirect()}>登入</button>
        )
        )

}

export default LoginButton;