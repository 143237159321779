import happyman from '../images/happyman.png';
import happyboy from '../images/happyboy.png';

function RecentComment(){
    return (
        <>
        <h4>最新評論{'\u{1F3C3}'}</h4>
        <div className="flex-container">
        <div className="row row-cols-2 row-cols-md-3 g-4 py-3 px-2">
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">將軍澳中心</h5>
                <h6 className="card-subtitle mb-2 text-muted">Teresa</h6>
                <p className="card-text">樓下有MTR, 商場, 落大雨都唔使濕身都出到街食野買野.有會所, 老人家可以去hea.</p>
                <a href="../location/tseungkwano" className="card-link">查看更多</a>
            </div>
        </div>
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">峻巒</h5>
                <h6 className="card-subtitle mb-2 text-muted">c7wc2018</h6>
                <p className="card-text">多蚊到喊，我係附近行過，睇下樓都被咬有BB就勸唔好買，因為一定咬到變豬頭</p>
                <a href="../location/yuenlong" className="card-link">查看更多</a>
            </div>
        </div>
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">榮輝中心</h5>
                <h6 className="card-subtitle mb-2 text-muted">KK3</h6>
                <p className="card-text">環境不錯，出金鐘要一個鐘，出地鐵站可以坐小巴或者巴士</p>
                <a href="../location/fanling" className="card-link">查看更多</a>
            </div>
        </div>
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">聚龍居</h5>
                <h6 className="card-subtitle mb-2 text-muted">Rachel Leung</h6>
                <p className="card-text">有蛇有馬騮有野豬，交通不便，但清靜治安好</p>
                <a href="../location/taiwai" className="card-link">查看更多</a>
            </div>
        </div>
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">又一居</h5>
                <h6 className="card-subtitle mb-2 text-muted">tungwl</h6>
                <p className="card-text">冇車要行去mtr都幾辛苦，附近店舖又不多，對追求方便的香港人不吸引。</p>
                <a href="../location/kowloontong" className="card-link">查看更多</a>
            </div>
        </div>
        <div className="card comment">
            <div className="card-body">
                <h5 className="card-title">瓏門</h5>
                <h6 className="card-subtitle mb-2 text-muted">WFY</h6>
                <p className="card-text">超級好住。繁正中間點 去邊都方便</p>
                <a href="../location/tuenmun" className="card-link">查看更多</a>
            </div>
        </div>
        </div>
        <div class="p-3 mb-3 bg-light rounded">
            <h2>找到理想的住宅</h2>
            <p className="lead">我們收集了香港100多套住宅評論。 搜尋你的目標住宅即可開始。</p>
            <img className="happyboy" src={happyboy}/>
        </div>
        </div>
        </>
        )
}

export default RecentComment;