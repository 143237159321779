import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const server_address = process.env.REACT_APP_DOMAIN || ""

function Review(){
    const { name } = useParams();
    const [reviews, setReview] = useState([]);

    useEffect(() => {
        async function getRecords() {
            const response = await fetch(`${server_address}record/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const result = await response.json();
            setReview(result);
        }

        getRecords();
        
        return;
    }, []);

    return (
        <div class="container mt-4" style={{ marginBottom: 570 }}>
        <h3>瀏覽 '{name}' 的評論</h3>
        <p>#評論按提交日期排序</p>
            <div class="review-card">
                {reviews
                .filter((apartment)=>apartment.ApartmentName.replace(/\s+/g, '').toLowerCase() === name)
                .sort((a,b) => {
                    if (a.CreateDate > b.CreateDate) return -1;
                    else if (a.CreateDate < b.CreateDate) return 1;
                    return 0;
                })
                .map((apartment)=>
                <div class="card reivew">
                    <>
                    <div class="card-body">
                        <p class="card-text">{apartment.Comment}</p>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <h6 style={{ marginRight: '10px' }}>交通配套:</h6>
                            {[...Array(apartment.Rating[0].RatingLocation)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'gold' }} />
                            ))}
                            {[...Array(5 - apartment.Rating[0].RatingLocation)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />
                            ))}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <h6 style={{ marginRight: '10px' }}>地區環境:</h6>
                            {[...Array(apartment.Rating[0].RatingCondition)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'gold' }} />
                            ))}
                            {[...Array(5 - apartment.Rating[0].RatingCondition)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />
                            ))}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <h6 style={{ marginRight: '10px' }}>社區配套:</h6>
                            {[...Array(apartment.Rating[0].RatingNeighborhood)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'gold' }} />
                            ))}
                            {[...Array(5 - apartment.Rating[0].RatingNeighborhood)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />
                            ))}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <h6 style={{ marginRight: '10px' }}>會所設施:</h6>
                            {[...Array(apartment.Rating[0].RatingAmenties)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'gold' }} />
                            ))}
                            {[...Array(5 - apartment.Rating[0].RatingAmenties)].map((_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />
                            ))}
                        </div>
                        <p class="card-title"><i>{apartment.Author}</i></p>
                        <p class="card-text"><small class="text-muted">{apartment.CreateDate.split('T')[0]}</small></p>
                    </div>
                    <div className="p-3 mb-5 rounded square" style={{     backgroundColor: 
                        apartment.Overall > 3.5 ? '#36D855':
                        apartment.Overall > 2.5 ? '#FFCC66': 
                        '#CC3333'}}>
                        <h2 style={{textAlign: 'center'}}>{apartment.Overall}</h2>
                        <p style={{textAlign: 'center'}}><bold>總得分</bold></p>
                    </div>
                    </>
                </div>
                )}
            </div>
        </div>
    );
}

export default Review;