import { Link } from 'react-router-dom';

function SearchResult(resultObject){

    return (
        <Link to={{ pathname: `/review/${resultObject.result.ApartmentName}`, state: { resultObject } }} style={{ textDecoration: 'none' }}>
            <div className="search-result" key={resultObject.result._id}>
            {resultObject.result.ApartmentName}
            </div>
        </Link>
    )
}

export default SearchResult