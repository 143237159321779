import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import livingroom from '../images/livingroom.jpeg';

const server_address = process.env.REACT_APP_DOMAIN || ""

function Location(){
    const { name } = useParams();
    const [apartments, setAppartment] = useState([]);
    const [showWriteReview, setShowWriteReview] = useState(false);
    const [reviewCounts, setReviewCounts] = useState({});
    const [apartmentRatings, setApartmentRating] = useState({});

    useEffect(() => {
        async function getRecords() {
            const response = await fetch(`${server_address}apartment/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const apartments = await response.json();
            setAppartment(apartments);
        }

        getRecords();
        return;
    }, []);

    useEffect(() => {
        async function fetchReviewCounts() {
          try {
            const countsPromises = apartments.flatMap((location) => {
                return location.apartment["$each"].map(async (apartmentName) => {
                  const response = await fetch(
                    `${server_address}record/review_count?field=${apartmentName}`,
                  );
              
                  if (!response.ok) {
                    return { name: apartmentName, count: 0 };
                  }
                  
                  const data = await response.json();
                  return { name: apartmentName, count: data.results, overall: data.overall};
                });
              });
            
            const counts = await Promise.all(countsPromises);

            const reviewCountsMap = counts.reduce((map, count) => {
              map[count.name] = count.count;
              return map;
            }, {});
    
            const apartmentRatingMap = counts.reduce((map2, object) => {
              map2[object.name] = object.overall;
              return map2;
            }, {});

            setReviewCounts(reviewCountsMap);
            setApartmentRating(apartmentRatingMap);
          } catch (error) {
            console.error('Error retrieving review counts:', error);
          }
        }    
        
        fetchReviewCounts();
      },[apartments]);

    useEffect(() => {
        const filteredApartments = apartments.filter((location) => location.area.replace(/\s+/g, '').toLowerCase() === name);
        if (filteredApartments.length === 0) {
            setShowWriteReview(true);
        } else {
            setShowWriteReview(false);
        }
    }, [apartments, name]);

    return (
        <div className="container main mt-4" /*style={{ marginBottom: 750 }}*/>
            <h4>瀏覽所有住宅</h4>
            <div className="row">
            {apartments
                .filter((location) => location.area.replace(/\s+/g, '').toLowerCase() === name)
                .map((location) =>
                location.apartment["$each"] && location.apartment["$each"].length > 0 ? (
                    location.apartment["$each"].map((apartmentName, index) => (
                    <div className="col-md-12" key={index}>
                        <Link to={{ pathname: `/review/${apartmentName}`, state: { apartmentName } }} style={{ textDecoration: 'none' }}>
                        <div className="card mb-3">
                            <div className="card-body">
                            <div className="row">
                                <div className="col-auto pr-0">
                                <img id="apartment-thumbnail" src={livingroom} className="square" alt="Living Room Image" width="90" height="90"/>
                                </div>
                                <div className="col">
                                <h5 className="card-title">{apartmentName}</h5>
                                <p className="card-text">整體評分: {apartmentRatings[apartmentName]}</p>
                                <p className="card-text">
                                    <small className="text-muted">總共: {reviewCounts[apartmentName]} 評論</small>
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    ))
                ) : null
                )}
            {showWriteReview && (
            <div className="col-md-12">
                <div class="alert alert-warning" role="alert">
                這個地區暫時未有找到任何結果
                </div>
            </div>
            )}
            </div>
        </div>
    )
}

export default Location;