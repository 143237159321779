import {useState} from "react"

const server_address = process.env.REACT_APP_DOMAIN || ""

function SearchFrom({setResult}){

    const [input, setInput] = useState("")
    
    function fetchData(value) {
      fetch(`${server_address}record/`)
        .then((response) => response.json())
        .then((json) => {
          const uniqueResults = [];
          const existingNames = [];
    
          json.forEach((object) => {
            if (
              value &&
              object &&
              object.ApartmentName &&
              object.ApartmentName.includes(value) &&
              !existingNames.includes(object.ApartmentName)
            ) {
              uniqueResults.push(object);
              existingNames.push(object.ApartmentName);
            }
          });
    
          setResult(uniqueResults);
        });
    }

    function handleChange(value){
        setInput(value)
        fetchData(value)
    }

    return (
        <div className="container">
            <form className="d-flex mt-3">
              <input className="form-control mr-2" type="search" placeholder="尋找住宅" aria-label="Search" value={input} onChange={(e) => handleChange(e.target.value)}/>
            {/*<button className="btn btn-outline-primary" type="submit" style={{ width: '80px' }}>搜尋</button>*/}
            </form>
        </div>
    )
}

export default SearchFrom;