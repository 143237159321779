import LoginButton from './loginbutton';
import LogoutButton from './logoutbutton';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {user, useAuth0} from '@auth0/auth0-react';
import {options} from './constant'
import {useState, useEffect} from "react";

const server_address = process.env.REACT_APP_DOMAIN || ""

function Navbar() {
  const { user, isAuthenticated} = useAuth0();

  const currDate = new Date();
  const formattedDate = currDate.toISOString();

  const [review, setReview] = useState({
      CreateDate: formattedDate, Author: "", Area: "", ApartmentName: "", Comment: "", 
      RatingLocation: 0, RatingCondition: 0, RatingNeighborhood: 0, RatingAmenties: 0
  });

  const [submitted, setSubmitted] = useState(false);

  function updateForm(value) {
      return setReview((prev) => {
        return { ...prev, ...value };
      });
  }

  function onSubmit(e) {
    e.preventDefault()
    setSubmitted(true)

    const newReview = { ...review };
    
    fetch(`${server_address}record/`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',},
      body: JSON.stringify(newReview),
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      console.log("isAuthenticated updated!", isAuthenticated)
      console.log("review: ", review)
    }

    if (isAuthenticated) {
      setReview(prev => ({
        ...prev,
        Author: user.name
      }));
    }
  }, [isAuthenticated]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">Rate My House - 香港住宅評論網</a>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">租客專區</a>
            </li>
          </ul>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <LoginButton/>
        {isAuthenticated && (
          <>
          <span className="navbar-text user-name">
            用戶: {user.name}
          </span>
        <Popup trigger={<button className="btn btn-primary" type="button">寫評論</button>} modal>
          {close => (
            <form className="my-3 mx-5">
              {!submitted && isAuthenticated && (
                <>
                <h4>寫評論</h4>
                <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">(請選擇地區)</label>
                <select className="form-control" id="area" value={review.Area} onChange={(e) => updateForm({ Area: e.target.value })}>
                    {options.map((area) => (
                    <option key={area.areaEN} value={area.areaEN}>
                        {area.area}
                    </option>
                    ))}
                </select>
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlTextarea1">(請填寫公寓名稱)</label>
                    <textarea className="form-control" id="apartmentname" value={review.ApartmentName} 
                        onChange={(e) => updateForm({ ApartmentName: e.target.value })} rows="1"></textarea>
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlTextarea1">(撰寫評論)</label>
                    <textarea className="form-control" id="comment" value={review.Comment} 
                        onChange={(e) => updateForm({ Comment: e.target.value })} rows="3"></textarea>
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlSelect1">(交通配套得分)</label>
                    <select className="form-control" id="RatingLocation" value={review.RatingLocation} 
                        onChange={(e) => updateForm({ RatingLocation: parseInt(e.target.value)})} defaultValue = {"5"}>
                        <option value = "5">5 (最滿意)</option>
                        <option value = "4">4</option>
                        <option value = "3">3</option>
                        <option value = "2">2</option>
                        <option value = "1">1 (最不滿意)</option>
                    </select>            
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlSelect1">(地區環境得分)</label>
                    <select className="form-control" id="RatingCondition" value={review.RatingCondition} 
                        onChange={(e) => updateForm({ RatingCondition: parseInt(e.target.value)})} defaultValue = {"5"}>
                        <option value = "5">5 (最滿意)</option>
                        <option value = "4">4</option>
                        <option value = "3">3</option>
                        <option value = "2">2</option>
                        <option value = "1">1 (最不滿意)</option>
                    </select>            
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlSelect1">(社區配套得分)</label>
                    <select className="form-control" id="RatingNeighborhood" value={review.RatingNeighborhood} 
                        onChange={(e) => updateForm({ RatingNeighborhood: parseInt(e.target.value)})} defaultValue = {"5"}>
                        <option value = "5">5 (最滿意)</option>
                        <option value = "4">4</option>
                        <option value = "3">3</option>
                        <option value = "2">2</option>
                        <option value = "1">1 (最不滿意)</option>
                    </select>            
                </div>
                <br/>
                <div className="form-group">
                    <label for="exampleFormControlSelect1">(會所設施得分)</label>
                    <select className="form-control" id="RatingAmenties" value={review.RatingAmenties} 
                        onChange={(e) => updateForm({ RatingAmenties: parseInt(e.target.value)})} defaultValue = {"5"}>
                        <option value = "5">5 (最滿意)</option>
                        <option value = "4">4</option>
                        <option value = "3">3</option>
                        <option value = "2">2</option>
                        <option value = "1">1 (最不滿意)</option>
                    </select>            
                </div>
                <br/>
                <button className="btn btn-primary" type="submit" onClick={onSubmit}>提交</button>
                <small className="text-muted"> 按下「提交」，你確認遵守並同意條款和細則及。</small>
                </>
              )}
              {submitted && (
                <>
                <h4>你的評論已經提交</h4>
                <p>我們正在審核你的評論!</p>
                </>
              )}
            </form>
          )}
        </Popup>
        </>
        )}
        <LogoutButton/>
      </div>
      </div>
    </nav>
  );
}

export default Navbar;