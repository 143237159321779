import {useAuth0} from '@auth0/auth0-react';

function LogoutButton(){
    const { logout, isAuthenticated} = useAuth0();

    return (
        isAuthenticated && (
            <button class="btn btn-primary" type="button" onClick={() => logout()}>登出</button>
        ) 
        )

}

export default LogoutButton;